.rspinner{
    width: 60px;
    height: 60px;
    
    border: 5px solid transparent;
    border-left-color:#5ccbe0;
    border-right-color:#5ccbe0;
    border-radius: 50%;
    
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    margin: auto;
    
    animation: rspin 1s infinite linear;
}

.spinner{
    width: 80px;
    height: 80px;
    
    border: 5px solid transparent;
    border-left-color:#584997;
    border-right-color:#584997;
    border-radius:50%;
    
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right: 0;
    margin: auto;
    
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
}

@keyframes rspin {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(-360deg);
    }
}

