
@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }

.ss3-card{
height: 350px;
max-width: 350px;
background-color: rgb(64, 128, 189);
border-radius: 30px;
transition: 0.5s all;
}

.ss3-card-inner{
display: flex;
flex-direction: column;
align-items: center;
padding: 60px;
}
.ss3-card-svg{
margin: auto;
width: 100px;
height: 100px;
}

.ss3-card-svg img{
    width:100%;
    height:100%;
}

.ss3-card-heading{
margin:20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight:900;
font-size:18px;
text-align:center;
color:#fff;
}

.ss3-card-description{
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 400;
font-size: 14px;
text-align: center;
line-height: 18px;
color:#fff;
}

