@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


.uc1{
background: linear-gradient(90deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
}

.uc1-data{
text-align: center;
width: 80%;
margin: auto;
padding-top: 75px;
padding-bottom: 75px;
}

.uc1-data-left h1{
font-family: "SofiaPro-Light";
font-size: 73px;
color: white;
width: 70%;
margin: auto;
}

.uc1-data-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
color: white;
width: 55%;
margin: 2rem auto auto auto;
text-align: center;
}

.uc2{
display: grid;
grid-template-rows: 1fr 1fr 1fr 1fr;
margin:3rem auto 3rem auto;
width:85%;
}

.ogc{
display:flex;
flex-direction: row;
}

.ogc-left{
display:flex;
flex-direction: column;
margin:2rem;
}

.ogc-left h2{
font-family: "SofiaPro-Light";
font-size: 20px;
font-weight: 700;
margin-bottom: 1rem;
}

.ogc-left h1{
font-family: "SofiaPro-Light";
font-size: 40px;
margin-bottom: 1rem;
}

.ogc-left h1:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
}

.ogc-left-mid{
display: flex;
flex-direction: row-reverse;
width: 90%;
}

.ogc-left-mid-link{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
font-weight: 600;
}

.ogc-left-mid-link:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left-down{
display: grid;
grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr;
width: 100%;
/* grid-gap: 10px; */
justify-items: center;
}

.ogc-left-down h1:hover{
    color:black;
}

.ogc-right img{
border-radius: 32px;
height: 70%;
transition: 0.3s;
}

.ogc-right img:hover{
transition: 0.3s;
transform:translateY(-20px);
}

.ogc-left-down-problem{
width:100%;
height:100%;
}

.ogc-left-down-problem h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-problem p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-solution{
width:100%;
height:100%;
}

.ogc-left-down-solution h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-solution p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-result{
width:100%;
height:100%;
}


.ogc-left-down-result h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-result p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}


.pulses{
float: left;
border: 1.5px solid;
border-top: 0;
border-bottom: 0;
border-left:0;
border-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(226, 226, 226, 1) 48%, rgba(255, 255, 255, 0) 100%);
border-image-slice: 1;
}

.nab{
display:flex;
flex-direction: row-reverse;
}


@media screen  and (max-width: 599px) {
.uc1-data-left h1 {
font-size: 40px; /* Adjust the font size for the heading */
width: 90%; /* Adjust the width as needed */
}

.uc1-data-left p {
font-size: 14px; /* Adjust the font size for the paragraph */
width: 80%; /* Adjust the width as needed */
margin: 1rem auto; /* Adjust the margin around the paragraph */
text-align: center; /* Center align the text */
line-height:15px;
}
}




@media screen  and (max-width: 599px) {


.ogc{
display:flex;
flex-direction: column-reverse;
}

.ogc-left{
display: flex;
flex-direction: column;
width: 100%;
margin: 0;
}

.ogc-left h2{
font-family: "SofiaPro-Light";
font-size: 20px;
font-weight: 700;
margin: 1rem 0rem 1rem 0rem;
}

.ogc-left h1{
font-family: "SofiaPro-Light";
font-size: 40px;
margin-bottom: 1rem;
}

.ogc-left h1:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
}

.ogc-left-mid{
display: flex;
flex-direction: row-reverse;
width: 94%;
}

.ogc-left-mid-link{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
font-weight: 600;
}

.ogc-left-mid-link:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left-down{
display: grid;
grid-template-rows: 1fr 0.1fr 1fr 0.1fr 1fr;
width: 95%;
justify-items: center;
grid-template-columns: 1fr;
}

.ogc-right img{
border-radius: 32px;
width: 90%;
height: 90%;
transition: 0.5s;
}

.ogc-left-down-problem{
width:100%;
height:100%;
}

.ogc-left-down-problem h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-problem p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-solution{
width:100%;
height:100%;
}

.ogc-left-down-solution h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-solution p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-result{
width:100%;
height:100%;
}


.ogc-left-down-result h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-result p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.pulses{
display:none;
}

.nab{
display:flex;
flex-direction: column-reverse;
}

}

@media screen and (min-width: 600px) and (max-width: 799px) {
.uc2 {
display: grid;
grid-template-rows: 1fr 1fr 1fr 1fr;
margin: 3rem auto 3rem auto;
width: 85%;
}
.ogc {
display: flex;
flex-direction: column-reverse;
}
.nab{
display:flex;
flex-direction: column-reverse;
}

.ogc-left{
display: flex;
flex-direction: column;
width: 100%;
margin: 0;
}

.ogc-left h2{
font-family: "SofiaPro-Light";
font-size: 24px;
font-weight: 700;
margin: 1rem 0rem 1rem 0rem;
}

.ogc-left h1{
font-family: "SofiaPro-Light";
font-size: 40px;
margin-bottom: 1rem;
}

.ogc-left h1:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
line-height:1rem;
}

.ogc-left-mid{
display: flex;
flex-direction: row-reverse;
width: 94%;
}

.ogc-left-mid-link{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
font-weight: 600;
}

.ogc-left-mid-link:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left-down{
display: grid;
grid-template-rows: 1fr 0.1fr 1fr 0.1fr 1fr;
width: 95%;
justify-items: center;
grid-template-columns: 1fr;
}

.ogc-right img{
border-radius: 32px;
width: 100%;
height: 100%;
}

.ogc-left-down-problem{
width:100%;
height:100%;
}

.ogc-left-down-problem h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-problem p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-solution{
width:100%;
height:100%;
}

.ogc-left-down-solution h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-solution p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-result{
width:100%;
height:100%;
}


.ogc-left-down-result h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-result p{
font-family: "SofiaPro-Light";
font-size: 12px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.pulses{
display:none;
}
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
.uc2 {
display: grid;
grid-template-rows: 1fr 1fr 1fr 1fr;
margin: 3rem auto 3rem auto;
width: 85%;
}
.ogc {
display: flex;
flex-direction: column-reverse;
}
.nab{
display:flex;
flex-direction: column-reverse;
}

.ogc-left{
display: flex;
flex-direction: column;
width: 100%;
margin: 0;
}

.ogc-left h2{
font-family: "SofiaPro-Light";
font-size: 24px;
font-weight: 700;
margin: 1rem 0rem 1rem 0rem;
}

.ogc-left h1{
font-family: "SofiaPro-Light";
font-size: 40px;
margin-bottom: 1rem;
}

.ogc-left h1:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
line-height:1rem;
}

.ogc-left-mid{
display: flex;
flex-direction: row-reverse;
width: 94%;
}

.ogc-left-mid-link{
font-family: "SofiaPro-Light";
font-size: 15px;
margin-bottom: 1rem;
font-weight: 600;
}

.ogc-left-mid-link:hover{
transition:0.5s;
color:#ffa600;
}

.ogc-left-down{
display: grid;
grid-template-rows: 1fr 0.1fr 1fr 0.1fr 1fr;
width: 95%;
justify-items: center;
grid-template-columns: 1fr;
}

.ogc-right img{
border-radius: 32px;
width: 100%;
height: 100%;
}

.ogc-left-down-problem{
width:100%;
height:100%;
}

.ogc-left-down-problem h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-problem p{
font-family: "SofiaPro-Light";
font-size: 16px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-solution{
width:100%;
height:100%;
}

.ogc-left-down-solution h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-solution p{
font-family: "SofiaPro-Light";
font-size: 16px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.ogc-left-down-result{
width:100%;
height:100%;
}


.ogc-left-down-result h1{
font-family: "SofiaPro-Light";
font-size: 20px;
margin-bottom: 1rem;
font-weight: 600;
width:50%;
}

.ogc-left-down-result p{
font-family: "SofiaPro-Light";
font-size: 16px;
margin-bottom: 1rem;
line-height: 22px;
width:100%;
}

.pulses{
display:none;
}
}
