@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }

.s2-dot-square-purple{
background-image: url('../Images/dot-shape-purple.png');
left: 0;
content: '';
position: absolute;
background-repeat: no-repeat;
background-size: contain;
width: 100px;
height: 100px;
}

.spacer
{
width: 100%;
height: 4rem;

}


.s-1{
width:100%;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
height: 750px;


}

.s1-button {
background: #f47458;
color: #fff;
border: none;
padding: 12px 26.2px 15px;
margin-top:20px;
border-radius: 60px;
cursor: pointer;
transition: background-color 0.3s ease;
font-size: 15px;
font-family: "Sofia Pro Regular";
}

.s1-button:hover {
background-color: #498ac5;
color: white;
}


.som{
display: grid;
grid-template-columns: 0.75fr 1fr;
width: 100%;
height: 90%;
padding-top: 75px;
}

.som-left{
margin:195px auto 0px auto;
width:65%;
}

.som-left h1{
font-family: "SofiaPro-Light";
font-size: 75px;
color: white;
width: 140%;
}

.som-left p{
width: 114%;
font-family: "SofiaPro-Light";
font-size: 21px;
color: white;
padding-top: 3rem;
line-height: 2rem;
}

.som-right{
height:100%;
}

.som-right img{
width: 75%;
border-radius: 30px;
object-fit: fill;
margin: 6rem;
display: block;
}


.s-2{
padding-top:100px;
}

.s-21{
margin:0 auto;
width:90%;
padding:0px 15px;
}

.s-211{
display:flex;
flex-direction: column;
align-items: center;
font-family: "SofiaPro-Light";
}

.our_sol{
font-size: 72px;
font-weight:500;
}

.our_blend{
margin: 0 173px;
padding-top: 20px;
font-size: 21px;
text-align: center;
line-height: 25px;
font-weight: 300;
color: black;
width:60%
}

.card-deck{
display:grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap:25px;
margin:3rem auto auto auto;
}



.s-3{
padding-top:4rem;
}

.dot-square-blue{
background-image: url('../Images/dot-shape-blue.png');
background-repeat: no-repeat;
background-size: contain;
}

.dot-square-purple{
background-image: url('../Images/dot-shape-purple.png');
background-repeat: no-repeat;
background-size: contain;
}

.s-31{
display:grid;
grid-template-rows: 0.6fr 1fr 1fr 1fr 1fr;
}


.modular-platform h1{
font-size: 69px;
font-family: "SofiaPro-Light";
width: 80%;
margin: auto;
text-align: center;
line-height: 80px;
}

.modular-platform p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 44%;
text-align: center;
margin: 2rem auto 0rem;
line-height: 24px;
}


.dc{
display:flex;
flex-direction:row;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
}

.dc-left{
width: 145%;
}
.dc-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 24px;
font-weight: 500;
}

.dc-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.dc-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.dc-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}

.ie{
display:flex;
flex-direction:row-reverse;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
padding-top:100px;
}

.ie-left{
width: 145%;
}
.ie-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 24px;
font-weight: 500;
}

.ie-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.ie-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.ie-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}


.eca{
display:flex;
flex-direction:row;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
padding-top:100px;
}

.eca-left{
width: 145%;
}
.eca-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 24px;
font-weight: 500;
}

.eca-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.eca-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.eca-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}


.di{
display:flex;
flex-direction:row-reverse;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
padding-top:100px;
}

.di-left{
width: 145%;
}
.di-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 24px;
font-weight: 500;
}

.di-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.di-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.di-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}


.s-4{
background-image: url("../Images/wcu.png");
width:100%;
margin-top:5rem;
}

.s-41{
width:100%;
margin:0 auto;
display: flex;
flex-direction: column;
}

.wcu h1{
margin: auto;
width: 80%;
text-align: center;
color: white;
font-family: "SofiaPro-Light";
font-size: 72px;
margin-top: 5rem;
}

.wcu p{
margin: auto;
width: 70%;
text-align: center;
color: white;
font-family: "SofiaPro-Light";
font-size: 18px;
margin-top: 2.5rem;
line-height: 25px;
font-weight:500;
}

.s4-card-deck{
display: grid;
grid-template-columns: 1fr 1fr 1fr;
height: 580px;
align-items: center;
width: 95%;
justify-items: center;
margin: auto;
}


.s-5{
width:100%;
margin-top:5rem;
}

.s-51{
width:100%;
margin:0 auto;
display: flex;
flex-direction: column;
}

.eocs h1{
margin: auto;
width: 80%;
color: black;
text-align: center;
font-family: "SofiaPro-Light";
font-size: 72px;
margin-top: 5rem;
}

.eocs p{
margin: auto;
width: 65%;
text-align: center;
color: black;
font-family: "SofiaPro-Light";
font-size: 21px;
margin-top: 2.5rem;
line-height: 25px;
font-weight: 500;
}

.s5-card-deck{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
grid-gap:2rem;
height: 580px;
align-items: center;
width: 95%;
justify-items: center;
margin: auto;
}

.s-6{
margin-top:3rem;
}

.wao{
width:80%;
margin:auto;
}

.wao h1{
font-family: "SofiaPro-Light";
font-size: 70px;
text-align: center;
width: 80%;
margin: auto;
}

.wao p{
font-family: "SofiaPro-Light";
font-size: 23px;
text-align: center;
width: 64%;
margin: 2rem auto auto auto;
}

.slider{
position:relative;
margin:2rem 0rem 5rem 0rem;
}

.s-7{
background:linear-gradient(90.59deg,#0f8589 -.79%,#584997 104.75%);
height:540px;
}

.s-71{
display:flex;
flex-direction: column;
}

.s-71 h1{
font-family: "SofiaPro-Light";
font-size: 47px;
font-weight: 500;
color: white;
width: 70%;
margin: 5rem auto 0rem auto;
text-align: center;
line-height: 4rem;
}

.s-71 p{
font-family: "SofiaPro-Light";
font-size: 21px;
margin: 2rem auto 2rem auto;
line-height: 25px;
font-weight: 500;
color: white;
width: 50%;
text-align: center;
}

.s-7 input[type="checkbox"]{
width:15px;
height:15px;
}

.inputWithButton {
text-align: center;
position: relative;
}

.inputWithButton input[type="email"] {
width: 35%; /* Adjust the width as needed */
margin: 1rem auto 1rem auto;
background: rgba(178, 217, 255, 0.5);
backdrop-filter: blur(25px);
border-radius: 60px;
border: 0;
color: #fff;
padding: 14px 27px;
line-height: 1.3;
}

.inputWithButton input[type="email"]::placeholder {
font-family: "SofiaPro-Light";
color: #fff;
}

.inputWithButton input[type="email"]:focus {
outline: none;
}

.inputWithButton button {
position: absolute;
top: 17.5px;
right: 31%;
height: 55%;
padding: 14px 20px;
border: none;
border-radius: 60px;
background-color: #362970;
color: #fff;
cursor: pointer;
font-family: "SofiaPro-Light";
font-size: 11px;
width: 10%;
}

/* Optional: Hover and Active Styles for the Button */
.inputWithButton button:hover {
transition:0.3s;
background-color: #5ccbe0; /* Change the hover background color as needed */
}

.inputWithButton button:active {
background-color: #5ccbe0; /* Change the active background color as needed */
}



.s-8{
background-image:url("../Images/s8.png");
height:600px;
background-repeat: no-repeat;
background-size: cover;
background-position: bottom right;
margin-bottom: 3rem;
}

.sales{
width:80%;
margin:auto;
display:grid;
grid-template-columns: 1fr 1fr;
height:80%;
padding-top:80px;
}

.sales-left{
margin:30px 0px;
}
.sales-left h1{
font-family: "SofiaPro-Light";
color: white;
font-size: 72px;
margin: 50px auto;
text-align: center;
width: 75%;
}

.sales-left p{
font-family: "SofiaPro-Light";
color: white;
font-size: 20px;
margin: 50px auto;
width: 65%;
}

.sales-email{
width:65%;
margin:auto;
}
.sales-right{
margin:30px 0px;
}

.sales-right form{
display: flex;
flex-direction: column;
height: 100%;
width: 80%;
margin: auto;
justify-content: center;
}

.sales-right input{
width: 80%;
margin: 1rem auto 1rem auto;
background: rgba(178,217,255,.5);
backdrop-filter: blur(25px);
border-radius: 60px;
border: 0;
color: #fff;
padding: 14px 27px;
line-height: 1.3;
}

.sales-right input::placeholder{
font-family: "SofiaPro-Light";
color: white;
}

.sales-right button{
width:40%;
margin:2rem auto 2rem auto;
}

.wpcf7-spinner {
display: inline-block;
background-color: #23282d;
opacity: 0.75;
width: 24px;
height: 24px;
border: none;
border-radius: 100%;
padding: 0;
margin: 0 24px;
position: relative;
transform: rotate(0deg);
transition: transform 1s linear; /* Smooth rotation transition */
}

.wpcf7-spinner:before {
content: '';
position: absolute;
background-color: #fbfbfc;
top: 4px;
left: 4px;
width: 6px;
height: 6px;
border: none;
border-radius: 100%;
transform-origin: 8px 8px;
animation-name: spin;
animation-duration: 1s;
animation-timing-function: linear;
animation-iteration-count: infinite;
}

.s1-button:active + .wpcf7-spinner {
transform: rotate(360deg); /* Rotate the spinner on button click */
}

@keyframes spin {
from {
transform: rotate(0deg);
}
to {
transform: rotate(360deg);
}
}


.spinner-out{
position: absolute;
right: 19%;
transform: translateY(155px);
display: none;
}

.spinner-out.appear {
display: block;
}
