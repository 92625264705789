@font-face {
font-family: "SofiaPro-Light";
src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
}

@font-face {
font-family: "Sofia Pro Regular";
src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
}


.story-section1{
background: linear-gradient(90deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
}

.story-section1-data{
text-align: center;
width: 80%;
margin: auto;
padding-top: 75px;
padding-bottom: 75px;
}

.story-section1-data-left h1{
font-family: "SofiaPro-Light";
font-size: 73px;
color: white;
width: 70%;
margin: auto;
}

.story-section1-data-left p{
font-family: "SofiaPro-Light";
font-size: 15px;
color: white;
width: 55%;
margin: 2rem auto auto auto;
text-align: center;
}


.story-section2{
height:600px;
background: linear-gradient(180deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
padding:100px 0px 0px 0px;
}

.testimonial-screen{
display:relative;
height:400px;
background:white;
width:90%;
margin:auto;
border-radius:30px;
}