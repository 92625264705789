@font-face {
font-family: "SofiaPro-Light";
src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
}

@font-face {
font-family: "Sofia Pro Regular";
src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
}

.is_sticky{
height:100px;
transition:0.5s;
border-bottom: 2px solid rgba(255,255,255,0.1);
}

.dropdown svg{
fill:white;
}

.dropdown:hover svg{
fill:black;
transition:0.1s;
transform:rotateZ(180deg);
}

/* Hover effect for the dropdown container */
.dropdown:hover {
transition:0.3s;
color: black; /* Change text color to black on hover */
fill: black; /* Change SVG fill color to black on hover */
transform: rotateZ(180deg);
}

/* Default styles for the dropdown menu */
.dropdown-nav {
width: 150px;
height: 100px;
border-radius: 30px;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
opacity: 0;
transition: transform 0.3s cubic-bezier(0.88, -0.72, 0, 1), opacity 0.3s ease-in-out;
transform: translateY(0rem);
list-style: none;
margin: 0;
padding: 0;
position: absolute;
z-index: 1;
/* display: flex; */
display:none;
flex-direction: column; /* Display items vertically */
justify-content: center; /* Center content vertically */
}

/* Hover styles for the dropdown container */
.dropdown:hover .dropdown-nav {
display:flex;
opacity: 1;
transform: translateY(0);
}

.demo-button {
background: #f47458;
color: #fff;
border: none;
padding: 12px 26.2px 15px;
border-radius: 60px;
cursor: pointer;
transition: background-color 0.3s ease;
font-size: 15px;
font-family: "Sofia Pro Regular";
}

.demo-button:hover {
background-color: #498ac5;
color: white;
}

.dropdown-nav li {
padding: 0;
margin: 0;
}

/* Styles for the dropdown menu links */
.dropdown-nav a {
color: white;
text-decoration: none;
transition: 0.3s; /* Smooth transition on hover */
font-size: 16px;
}



nav {
position: fixed;
z-index: 10;
left: 0;
right: 0;
top: 0;
font-family: "Sofia Pro Regular";
height: 120px;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
padding: 0 5%;
transition:0.5s;

}
nav .logo { 
float: left;
width: 16%;
height: 100%;
display: flex;
font-size: 24px;
color: #fff;
align-items: center;
flex-direction: row-reverse;
}

nav .logo img{
padding-bottom:0;
}

nav .links {
float: right;
padding: 0;
margin: 0;
width: 60%;
height: 100%;
display: flex;
justify-content: space-around;
align-items: center;
}

nav .links li {
list-style: none;
transition: transform 0.3s; /* Smooth transition on hover */
}

nav .links li:hover {
transform: translateY(-5px); /* Move the item 5px upwards on hover */
}

nav .links a {
display: block;
padding: 1em;
font-size: 16px;
color: #fff;
text-decoration: none;
position: relative;
transition:0.2s;
}
nav .links a:hover {
transition: 0.5s;
color: black;
}

#nav-toggle {
position: absolute;
top: -100px;
}

nav .icon-burger {
display: none;
position: absolute;
right: 5%;
top: 50%;
transform: translateY(-50%);
}

nav .icon-burger .line {
width: 30px;
height: 5px;
background-color: #fff;
margin: 5px;
border-radius: 3px;
transition: all .5s ease-in-out;
}




@media screen and (max-width: 599px) {

nav{
height:100px;
}

.is_sticky{
height:90px;
}
        


nav .logo {
float: none;
width: auto;
justify-content: center;
}

nav .links {
float: none;
position: fixed;
z-index: 9;
left: 0;
right: 0;
top: 85px;
bottom: 100%;
width: auto;
height: auto;
flex-direction: column;
justify-content: space-evenly;
background-color: rgba(0, 0, 0, .8);
overflow: hidden;
transition: all .5s ease-in-out;
}
nav .links a {
font-size: 14px;
}

nav .links a:hover {
transition: 0.5s;
color: white;
}

nav :checked ~ .links {
bottom: 0;
}

.dropdown-nav {
transform: translate3d(5rem,-5rem,0rem);
width:111px;
height:120px;
border-radius: 30px;
display:none;
align-items: center;

}

.dropdown:hover svg{
transform:rotateZ(270deg);
}
/* Hover styles for the dropdown container */
.dropdown:hover .dropdown-nav {
display:flex;
opacity: 1;
transform:translate3d(6rem,-5rem,0rem);
}


nav .icon-burger {
display: block;
}
nav :checked ~ .icon-burger .line:nth-child(1) {
transform: translateY(10px) rotate(225deg);
}
nav :checked ~ .icon-burger .line:nth-child(3) {
transform: translateY(-10px) rotate(-225deg);
}
nav :checked ~ .icon-burger .line:nth-child(2) {
opacity: 0;
}
}

@media screen and (min-width:600px) and (max-width: 799px) {

nav{
height:120px;
}

.is_sticky{
height:100px;
}

nav .logo {
float: none;
width: auto;
justify-content: center;
}

nav .links {
float: none;
position: fixed;
z-index: 9;
left: 0;
right: 0;
top: 100px;
bottom: 100%;
width: auto;
height: auto;
flex-direction: column;
justify-content: space-evenly;
background-color: rgba(0, 0, 0, .8);
overflow: hidden;
transition: all .5s ease-in-out;
}
nav .links a {
font-size: 20px;
}

nav .links a:hover {
transition: 0.5s;
color: white;
}

nav :checked ~ .links {
bottom: 0;
}

.dropdown-nav {
transform: translate3d(9rem,-5rem,0rem);
width:160px;
height:120px;
border-radius: 30px;
display:none;
align-items: center;

}

.dropdown:hover svg{
transform:rotateZ(270deg);
}
/* Hover styles for the dropdown container */
.dropdown:hover .dropdown-nav {
opacity: 1;
display:flex;
transform:translate3d(8rem,-5rem,0rem);
}


nav .icon-burger {
display: block;
}
nav :checked ~ .icon-burger .line:nth-child(1) {
transform: translateY(10px) rotate(225deg);
}
nav :checked ~ .icon-burger .line:nth-child(3) {
transform: translateY(-10px) rotate(-225deg);
}
nav :checked ~ .icon-burger .line:nth-child(2) {
opacity: 0;
}
}

@media screen and (min-width:800px) and (max-width: 1319px) {

nav{
height:120px;
}

.is_sticky{
height:100px;
}

nav .logo {
float: none;
width: auto;
justify-content: center;
}

nav .links {
float: none;
position: fixed;
z-index: 9;
left: 0;
right: 0;
top: 100px;
bottom: 100%;
width: auto;
height: auto;
flex-direction: column;
justify-content: space-evenly;
background-color: rgba(0, 0, 0, .8);
overflow: hidden;
transition: all .5s ease-in-out;
}
nav .links a {
font-size: 20px;
}

nav .links a:hover {
transition: 0.5s;
color: white;
}

nav :checked ~ .links {
bottom: 0;
}

.dropdown-nav {
transform: translate3d(9rem,-5rem,0rem);
width:160px;
height:120px;
border-radius: 30px;
display:none;
align-items: center;

}

.dropdown:hover svg{
transform:rotateZ(270deg);
}
/* Hover styles for the dropdown container */
.dropdown:hover .dropdown-nav {
transition: 0.5s;
opacity: 1;
display: flex;
transform:translate3d(8rem,-5rem,0rem);
}


nav .icon-burger {
display: block;
}
nav :checked ~ .icon-burger .line:nth-child(1) {
transform: translateY(10px) rotate(225deg);
}
nav :checked ~ .icon-burger .line:nth-child(3) {
transform: translateY(-10px) rotate(-225deg);
}
nav :checked ~ .icon-burger .line:nth-child(2) {
opacity: 0;
}
}