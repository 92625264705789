/* Add this CSS to style your InfiniteSlider component */

@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


/* Container for the entire slider */
.testimonial-infinite-slider {
display: flex;
width: 80%;
margin: 0 auto 3rem auto;
padding: 20px;
align-items: center;
justify-content: center;
}

/* Buttons for navigating the slider */
.testimonial-infinite-slider button {
background: none;
border: none;
color: #333;
font-size: 24px;
}


#testimonial-prev{
position: absolute;
left: 48%;
bottom: -220%;
fill:#DFDFDF;
stroke: #DFDFDF;
}

#testimonial-prev svg:hover{
fill:orange;
stroke:orange;
transition:0.5s;
cursor:pointer;
}

#testimonial-next{
position: absolute;
right: 47%;
bottom: -220%;
fill:#DFDFDF;
stroke: #DFDFDF;
}

#testimonial-next svg:hover{
fill:orange;
transition:0.5s;
cursor:pointer;
fill:orange;
stroke:orange;
}


/* Slider container */
.testimonial-slider-container {
display: flex;
overflow: hidden;
width: 95%;
}




/* Individual slide */
.testimonial-slide {
flex: 0 0 auto;
padding: 20px;
text-align: center;
margin-left: 2rem;
border:1px solid black;
width: 80%;
height: 225px;
display: block;
transition: transform 0.3s ease; /* Add transition for smooth sliding */
transform: translateX(0); /* Initialize transform property */
font-family: "SofiaPro-Light";
background:white;
border-radius:30px;
}
/* 
.testimonial-slide{
    transform: scale(1.05);
    transition: transform 0.3s ease;
} */


.testimonial-slide img{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
}

.testimonial-svg{
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
    margin: auto;
    border:1px solid rgb(79, 78, 78);
}

.testimonial-slide p{
    font-size: 20px;
    margin: 10px auto 5px auto;
    line-height:25px;
}

.testimonial-slide h1{
    font-size: 18px;
    margin: 10px auto auto auto;
    background-image: linear-gradient(180deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
    background-clip: text;
    color:transparent;
}
.testimonial-out{
    position:relative;
    width:85%;
    margin:auto;
    padding: 50px 0px 50px 0px;
}

@media screen and (max-width:1534px){
#testimonial-prev{
display:none;
}

#testimonial-next{
display:none;
}
}


@media screen and (max-width:399px){
    .testimonial-slide{
        width:54%;
    }

    .testimonial-slide p{
        font-size:11px;
        line-height:12px;
    }

    .testimonial-slide h1{
        font-size:12px;
    }
}


@media screen and (min-width:400px) and (max-width:499px){
    .testimonial-slide{
        width:65%;
    }

    .testimonial-slide p{
        font-size:17px;
        line-height:17px;
    }

    .testimonial-slide h1{
        font-size:12px;
    }
}


@media screen and (min-width:500px) and (max-width:699px){
    .testimonial-slide{
        width:70%;
    }

    .testimonial-slide p{
        font-size:15px;
        line-height:15px;
    }

    .testimonial-slide h1{
        font-size:12px;
    }
}


@media screen and (min-width:700px) and (max-width:799px){
    .testimonial-slide{
        width:80%;
    }

    .testimonial-slide p{
        font-size:20px;
        line-height:19px;
    }

    .testimonial-slide h1{
        font-size:20px;
    }
}

@media screen and (min-width:800px) and (max-width:999px){
    .testimonial-slide{
        width:65%;
    }

    .testimonial-slide p{
        font-size:12px;
        line-height:15px;
    }

    .testimonial-slide h1{
        font-size:12px;
    }
}


@media screen and (min-width:1000px) and (max-width:1199px){
    .testimonial-slide{
        width:70%;
    }

    .testimonial-slide p{
        font-size:15px;
        line-height:20px;
    }

    .testimonial-slide h1{
        font-size:16px;
    }
}