@font-face {
font-family: "SofiaPro-Light";
src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
}

@font-face {
font-family: "Sofia Pro Regular";
src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
}

.custom-footer {
background-color: #fff;
color: #333;
padding-bottom:100px;
}

.footer-top {
display: grid;
grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
width: 86%;
height: 280px;
margin: 0 auto 2rem auto;

}

.footer-column {
flex: 1;
padding-left: 30px;
}

.footer-column p{
font-family: "SofiaPro-Light";
padding-bottom:0.5rem;
font-weight:500;
font-size:16px;
}

.logo img {
height:20px;
width:150px;
padding-bottom:1.5rem;
}

.column-heading {
color: #282828;
font-size: 24px;
font-weight: 700;
line-height: 24px;
font-family: "Sofia Pro Regular";
padding-bottom:25px;
}

.column-list {
list-style: none;
padding: 0;
}

.column-list li {
color: #4b4b4b;
font-family: "Sofia Pro Regular";
font-size: 18px;
font-weight: 400;
line-height: 36px;

}

.column-list a:visited,a:active,a{
text-decoration:none;
color:inherit;
}

.column-list a:hover{
color:#ff6600;
transition:0.5s;
}


.footer-bottom {
display: flex;
justify-content: space-between;
align-items: center;
width: 82%;
margin:0 auto;
color: #000;
}

.footer-bottom a,a:visited{
text-decoration: none;
color: #4b4b4b;
transition: color 0.3s ease;
}

.footer-bottom a:hover {
color: #ff6600; /* Orange color on hover */
}

.copyright-text {
flex: 1;
font-family: "Sofia Pro Regular";
width:50%
}


.email-link{
font-size: 20px;
color: #292929;
}

.footer-links{
display: flex;
align-items: center;
}

.footer-links a{
padding-left:5px;
transition: color 0.3s ease;
font-family: "Sofia Pro Regular";
font-weight:500;
transition: 0.3s;
color:#000;
}

.footer-links a:hover{
color: #ff6600; /* Orange color on hover */
transition: 0.3s;
}

.footer-links svg:hover {
color: #ff6600; /* Orange color on hover */
transition: 0.3s;
}






/* CSS for screen sizes between 499px and 599px */
@media screen  and (max-width: 599px) {
.custom-footer {
padding-bottom: 30px; /* Remove the padding for the footer */
}

.footer-top {
display: grid;
grid-template-columns: 1fr 1fr; /* Two columns for the first row */
grid-template-rows: repeat(2, 1fr); /* Two rows for the remaining columns */
grid-template-areas:
"first-column first-column"
"second-column third-column"
"fourth-column fifth-column"; /* Define the grid areas */
height: auto; /* Adjust the height to fit content */
margin: auto auto 2rem auto; /* Add margin below the footer top */
}

.footer-column {
flex: none; /* Remove flex property to stack columns */
padding: 20px; /* Add padding to separate columns */
}

.column-heading {
color: #282828;
font-size: 20px;
font-weight: 700;
line-height: 24px;
font-family: "Sofia Pro Regular";
padding-bottom: 25px;
text-align: left;
}

.column-list li {
color: #4b4b4b;
font-family: "Sofia Pro Regular";
font-size: 13px;
line-height: 20px;
text-align: left;
}

.footer-column:first-child {
grid-area: first-column; /* Assign grid area to the first column */
padding-right: 20px; /* Add right padding to the first column */
}

.footer-column:nth-child(2) {
grid-area: second-column; /* Assign grid area to the second column */
}

.footer-column:nth-child(3) {
grid-area: third-column; /* Assign grid area to the third column */
}

.footer-column:nth-child(4) {
grid-area: fourth-column; /* Assign grid area to the fourth column */
}

.footer-column:nth-child(5) {
grid-area: fifth-column; /* Assign grid area to the fifth column */
}

.footer-bottom {
flex-direction: column-reverse; /* Reverse the order of elements */
align-items:center; /* Align elements to the start of the column */
width: 100%; /* Take full width of the screen */
}

.copyright-text {
font-size: 14px; /* Reduce font size for copyright text */
text-align: center; /* Center the copyright text */
margin-top: 20px; /* Add margin at the top */
width: 100%; /* Take full width of the column */
}
}

/* CSS for screen sizes between 600px and 900px */
@media screen and (min-width: 600px) and (max-width: 799px) {
.custom-footer {
padding-bottom: 30px; /* Remove or adjust the padding for the footer */
}

.footer-top {
grid-template-columns: repeat(3, 1fr); /* Three columns for the first row */
grid-template-rows: repeat(2, 1fr); /* Two rows for the remaining columns */
grid-template-areas:
"first-column second-column third-column"
"fourth-column fifth-column fifth-column"; /* Define the grid areas */
height: auto; /* Adjust the height to fit content */
}

.footer-column {
flex: none; /* Remove flex property to stack columns */
padding: 20px; /* Add padding to separate columns */
}

.column-heading {
font-size: 20px; /* Adjust font size for column headings */
padding-bottom: 15px; /* Reduce padding below headings */
}

.column-list li {
font-size: 16px; /* Adjust font size for list items */
line-height: 24px; /* Adjust line height for list items */
}

.footer-column:first-child {
padding-right: 20px; /* Add right padding to the first column */
}

.footer-column:nth-child(2) {
/* Adjust styles for the second column */
}

.footer-column:nth-child(3) {
/* Adjust styles for the third column */
}

.footer-column:nth-child(4) {
/* Adjust styles for the fourth column */
}

.footer-column:nth-child(5) {
/* Adjust styles for the fifth column */
}

.footer-bottom {
flex-direction: row; /* Reset the direction to horizontal */
justify-content: space-between; /* Restore the original spacing */
}

.copyright-text {
font-size: 14px; /* Adjust font size for copyright text */
margin-top: 0; /* Remove top margin for copyright text */
}
}


/* CSS for screen sizes between 901px and 1399px */
@media screen and (min-width: 800px) and (max-width: 1300px) {
.custom-footer {
padding-bottom: 40px; /* Adjust the padding for the footer */
}

.footer-top {
width: 90%; /* Adjust the width of the footer content */
margin: 0 auto 2rem auto; /* Center the footer content and add margin */
display: grid;
grid-template-columns: 1fr 1fr; /* Two columns for the first row */
grid-template-rows: 1fr 1fr; /* Two rows for the remaining columns */
grid-template-areas:
"first-column second-column"
"third-column fourth-column"; /* Define the grid areas */
height: auto; /* Adjust the height to fit content */
}

.footer-column {
padding: 20px; /* Adjust padding to separate columns */
}

.column-heading {
font-size: 22px; /* Adjust font size for column headings */
padding-bottom: 20px; /* Adjust padding below headings */
}

.column-list li {
font-size: 18px; /* Adjust font size for list items */
line-height: 28px; /* Adjust line height for list items */
}

.footer-column:first-child {
padding-right: 30px; /* Add right padding to the first column */
}

/* You can adjust styles for other columns as needed */

.footer-bottom {
margin-top: 20px; /* Add margin at the top of the footer bottom */
}

.copyright-text {
font-size: 16px; /* Adjust font size for copyright text */
}
}
