
@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }

.ss5-card{
height: 220px;
width: 250px;
transition: 0.5s all;
border: 1px dashed #fff;
border-radius: 32px;
}

.ss5-card-inner{
display: flex;
flex-direction: column;
align-items: center;
padding: 50px 20px;
}


.ss5-card-heading{
margin:20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight:900;
font-size:18px;
text-align:center;
color:#fff;
}

.ss5-card-description{
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 400;
font-size: 14px;
text-align: center;
line-height: 18px;
color:#fff;
}

