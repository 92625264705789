/* Add this CSS to style your InfiniteSlider component */

@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


/* Container for the entire slider */
.image-infinite-slider {
display: flex;
width: 80%;
margin: 0 auto 3rem auto;
padding: 20px;
align-items: center;
justify-content: center;
}

/* Buttons for navigating the slider */
.image-infinite-slider button {
background: none;
border: none;
color: #333;
font-size: 24px;
}


#image-prev{
position: absolute;
left: 48%;
bottom: -220%;
fill:#DFDFDF;
stroke: #DFDFDF;
}

#image-prev svg:hover{
fill:orange;
stroke:orange;
transition:0.5s;
cursor:pointer;
}

#image-next{
position: absolute;
right: 47%;
bottom: -220%;
fill:#DFDFDF;
stroke: #DFDFDF;
}

#image-next svg:hover{
fill:orange;
transition:0.5s;
cursor:pointer;
fill:orange;
stroke:orange;
}

/* Slider container */
.image-slider-container {
display: flex;
overflow: hidden;
width: 95%;
}

/* Individual slide */
.image-slide {
flex: 0 0 auto;
padding: 20px;
text-align: center;
margin-left: 1rem;
border-radius: 30px;
width: 85%;
height: 360px;
display: block;
transition: transform 0.3s ease; /* Add transition for smooth sliding */
transform: translateX(0); /* Initialize transform property */
}

.image-slide img{
height: 100%;
width: 100%;
border-radius: 30px;
object-fit:cover;
}

.image-out{
    position:relative;
    width:85%;
    margin:auto;
}

@media screen and (max-width:979px) {
.image-slider-container {
width: 355px;
}

}

@media screen and (min-width:980px) and (max-width:1300px) {
.image-slider-container {
width: 710px;
}


}

@media screen and (min-width:1301px) {
.image-slider-container {
width: 1055px;
}
}

@media screen and (max-width:1534px){
#image-prev{
display:none;
}

#image-next{
display:none;
}
}