@font-face {
font-family: "SofiaPro-Light";
src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
}

@font-face {
font-family: "Sofia Pro Regular";
src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
}

.slide-out{

    width:85%;
    margin:auto;
}




.carousel-item{
padding: 20px;
text-align: center;
border: 1px solid #ccc;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
margin: auto;
border-radius: 30px;
width: 325px;
height: 525px;
display: flex;
flex-direction: column;
align-items: center;
}

.carousel-item p{
font-family: "SofiaPro-Light";
font-size: 20px;
width: 100%;
margin-top: 30px;
line-height: 23px;
text-align: center;
}

.carousel-item h1{
font-family: "SofiaPro-Light";
font-size: 20px;
width: 100%;
text-align: center;
font-weight: 800;
margin: 0; /* Remove any default margin */
}

.carousel-item h2{
font-family: "SofiaPro-Light";
font-size: 14px;
width: 100%;
margin: 0; /* Remove any default margin */
}
/* Container for the entire carousel-itemr */

.b-rectangle {
    width: 95%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 48%;
    text-align: center;
    padding: 0px 0px 0px 30px;
}

/* Buttons for navigating the carousel-item */
.s-button {
    background-color: rgb(73 136 196/30%);
    color: #333;
    font-size: 24px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 50%;
    height: 56px;
    width: 56px;
    border-color: transparent;
}

.s-button:hover {
    background-color: #6ec1e4;
    transition: 0.5s;
}

#bl {
    /* Remove float and change display to block */
    display: block;
}

#br {
    /* Remove float and change display to block */
    display: block;
}

#signatory{
margin: 50px auto 50px auto;
display: grid;
grid-template-rows: 0fr 2fr .21fr .11fr;
align-items: stretch;
height: 100%;
justify-items: center;
}

#signatory img{
width:100px;
height:100px;
margin: auto;
}


@media screen and (max-width:1399px){
    #bl,#br,.b-rectangle{
        display:none;
    }
}


@media screen and (max-width:599px){
    .carousel-item{
        width:87%;
        height:520px;
        padding:15px;
    }

    .carousel-item p{
        font-size: 16px;
    }

    .carousel-item h1{
        font-size:17px;
    }


}