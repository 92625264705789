@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }



.loader{
position: absolute;
top:0;
bottom:0;
left:0;
right:0;
margin:auto;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
-webkit-border-radius:50px;
-moz-border-radius:50px;
border-radius:50%;
width:100px;
height:100px;
animation: spin 1s infinite linear;

}

.loader:after {
position: absolute;
display: block;
top: 5px;
left: 5px;
width: 90px;
height: 90px;
content: "";
background-color: #fff;
border-radius: 50%;
overflow: hidden;
}

@keyframes spin {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(360deg);
    }
}

@keyframes rspin {
    from{
        transform: rotate(0deg);
    }to{
        transform: rotate(-360deg);
    }
}

.a1{
width:100%;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
height: 750px;
margin-bottom:30rem;
}

.aom{
width: 100%;
height: 90%;
padding-top: 75px;
text-align:center;
}

.aom h1{
font-family: "SofiaPro-Light";
font-size: 72px;
width: 50%;
margin: auto;
text-align: center;
color: white;
}

.aom p{
font-family: "SofiaPro-Light";
font-size: 19px;
margin: 2rem auto 2rem auto;
width: 72%;
text-align: center;
color: white;
}

.ab-container{
    position: relative;
    display: inline-block;
    margin-top: 5rem;
}

.ab-container img{
    max-width: 80%;
    height: auto;
    display: block;
    margin: auto;
    border-radius: 30px;
}

.ab-container video{
    max-width: 80%;
    height: auto;
    display: block;
    margin: auto;
    border-radius: 30px;
}

.yt-button{
    background-image: url("../Images/youtube.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    position: absolute;
    width: 10%;
    padding-top: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 85px;
    }
    
    .yt-button:hover{
    cursor:pointer;
    }

.a1-button {
background: #f47458;
color: #fff;
border: none;
padding: 12px 26.2px 15px;
margin-top: 20px;
border-radius: 60px;
cursor: pointer;
font-size: 15px;
font-family: "Sofia Pro Regular";
transition: transform 0.3s, background-color 0.3s;
}

.a1-button:hover {
background-color: #8ed1fc;
transform: translateY(-5px) translateZ(20px); /* Adjust the values to control the float effect */
box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3); /* Add a shadow for depth */
color: white;
}




.a2{
margin-bottom:2rem;
}

.a2 h1{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 50%;
margin: auto auto 2rem auto;
text-align: center;
font-weight: 700;
}

.a2 p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 75%;
margin: 2rem auto 2rem auto;
text-align: center;
line-height: 2rem;
}

/* SECTION A1 */
@media screen  and (max-width: 599px) {
.a1{
width: 100%;
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
height: 500px;
margin-bottom: 15rem;
}

.aom{
width: 100%;
height: 90%;
padding-top: 75px;
text-align:center;
}

.aom h1{
font-family: "SofiaPro-Light";
font-size: 72px;
width: 100%;
margin: auto;
text-align: center;
color: white;
}

.aom p{
font-family: "SofiaPro-Light";
font-size: 15px;
margin: 2rem auto 2rem auto;
width: 93%;
text-align: center;
color: white;
line-height: 1rem;
}


.a1-img{
margin: auto;
display: block;
width: 80%;
position: relative;
left: 0;
bottom: -9%;
margin-bottom: 3rem;
}



.about-video{
margin: auto;
display: block;
width: 80%;
position: relative;
left: 0;
bottom: -9%;
margin-bottom: 3rem;
}



.a2 h1{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 88%;
text-align: center;
line-height: 2rem;
}

.a2 p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 88%;
text-align: center;
line-height: 2rem;
}
}

@media screen and (min-width: 600px) and (max-width: 799px) {
.a1 {
height: 500px;
margin-bottom: 15rem;
}

.aom h1 {
font-size: 48px; /* Adjust font size for h1 on medium-sized screens */
width: 90%; /* Adjust width for better readability */
}

.aom p {
font-size: 15px; /* Adjust font size for p on medium-sized screens */
margin: 1.5rem auto; /* Adjust margin for better spacing */
width: 90%; /* Adjust width for better readability */
line-height: 1.2rem; /* Adjust line-height */
}

.a1-button {
font-size: 14px; /* Adjust font size for the button on medium-sized screens */
padding: 10px 22px 13px; /* Adjust padding for the button */
}

.a1-img {
bottom: -12%; /* Adjust the image position on medium-sized screens */
}


.about-video{
bottom: -12%;
}
}

@media screen and (min-width: 800px) and (max-width: 1300px) {

.aom h1 {
font-size: 72px; /* Restore the original font size for h1 */
width: 50%; /* Restore the original width */
}

.aom p {
font-size: 19px; /* Restore the original font size for p */
margin: 2rem auto; /* Restore the original margin */
width: 72%; /* Restore the original width */
line-height: 1.5rem; /* Restore the original line-height */
}

.a1-button {
font-size: 15px; /* Restore the original font size for the button */
padding: 12px 26.2px 15px; /* Restore the original padding for the button */
}

.a1-img .about-video {
bottom: -47%; /* Restore the original image position for larger screens */
}


}


@media screen and (min-width: 600px) and (max-width: 799px) {
.a2 h1 {
font-size: 28px; /* Adjust font size for h1 on medium-sized screens */
width: 88%; /* Adjust width for better readability */
margin: auto auto 1.5rem auto; /* Adjust margin for better spacing */
line-height: 2rem; /* Adjust line-height */
}

.a2 p {
font-size: 18px; /* Adjust font size for p on medium-sized screens */
width: 88%; /* Adjust width for better readability */
margin: 1.5rem auto; /* Adjust margin for better spacing */
line-height: 1.8rem; /* Adjust line-height */
}
}


@media screen and (min-width: 800px) and (max-width: 1300px) {
.a2 h1 {
font-size: 32px; /* Adjust font size for h1 on larger screens */
width: 50%; /* Restore the original width */
margin: auto auto 2rem auto; /* Restore the original margin */
line-height: 2rem; /* Restore the original line-height */
}

.a2 p {
font-size: 20px; /* Adjust font size for p on larger screens */
width: 75%; /* Restore the original width */
margin: 2rem auto; /* Restore the original margin */
line-height: 2rem; /* Restore the original line-height */
}


}


@media screen and (min-width:800px) and (max-width:1059px){
    .a1 {
        height: 750px;
        margin-bottom: 13rem;
        }
}

@media screen and (min-width:1060px) and (max-width:1299px){
    .a1 {
        height: 750px;
        margin-bottom: 16rem;
        }
}

@media screen and (min-width:1061px) {
    .a1 {
        height: 750px;
        margin-bottom: 24rem;
        }
}


