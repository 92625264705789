

@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


.s5-card{
height: 450px;
width: 275px;
background-color: #f6fbff;
border-radius: 30px;
transition: 0.5s all;
}

.s5-card:hover{
box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);
transition: box-shadow 0.3s ease-in-out;
}

.s5-card-inner{
display: flex;
flex-direction: column;
align-items: center;
padding: 20px;
}
.s5-card-img img{
margin:auto;
width:100%;
height:100%;
border-radius: 30px;
}

.s5-card-heading{
margin:20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight:900;
font-size:22px;
color:#000;
}

.s5-card-description{
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 400;
font-size: 14px;
line-height: 20px;
color:#000;
}

.s5-card-link {
text-decoration: none; 
}

.s5-card-sentence {
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 800;
font-size: 16px;
text-align: center;
color: #000;
display: flex; /* Display as a flex container */
align-items: center; /* Vertically center the content */
}

/* Add these styles for the arrow */
.s5-card-sentence svg {
margin-left: 5px; /* Adjust the spacing between text and arrow */
}

.s5-card-link:hover .s5-card-sentence,
.s5-card-link:hover .s5-card-sentence svg {
transition:0.3s;
color: #6ec1e4;
fill: #6ec1e4; 
cursor: pointer;
}
