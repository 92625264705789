.f1{
    font-family: 'SofiaPro-Light';
    width: 80%;
    padding-top: 7rem;
    margin: auto auto 60px auto;
    display:grid;
    grid-template-columns: 1.3fr 0.5fr;

}

.f1-left{
    padding-right: 30px;
    border-right-width: 1px;
    border-style: solid;
    border-color: #f1f1f1;
}

.f1-left img{
    position:relative;
    margin-bottom:1rem;
    width:100%;
    padding-top:20px;
    
}

.f1-left p{
    color: #4a4a4a;
    margin: 0 0 20px;
    width: 90%;
    font-size: 15px;
    line-height: 25px;

}

.mheading{
    padding: 0 0 20px;
    margin: 0 0 15px;
    border-bottom: 1px solid #f1f1f1;
    letter-spacing: .6px;
}

.f1-left h1{
    font-size:37px;
    margin: 0rem 0rem 2rem 0rem;
}

.f1-left h2{
    margin: 0rem 0rem 2rem 0rem;
    font-size: 32px;
    font-weight: 600;
}

.f1-left-link{
    border-bottom: dotted 1px blue;
    text-underline-offset: 3px;
    text-decoration: none;
    transition:0.3s;
    color:black;
}

.f1-left-link:hover{
    cursor: pointer;
    color:#fcb900;
    transition:0.3s;
}

.f1-left-link2{
    border-bottom: solid 1px black;
    text-underline-offset: 3px;
    text-decoration: none;
    transition:0.3s;
    color:black;
}

.f1-left-link2:hover{
    cursor: pointer;
    color:#fcb900;
    transition:0.3s;
    border-bottom: dotted 1px blue;
}


.f1-left-list{
    padding-top: 30px;
    border-top-width: 1px;
    border-bottom-width: 1px;
    padding-bottom: 30px;
    border-style: solid;
    border-color: #f1f1f1;
}

.imte-display{
    display:grid;
    grid-template-columns:1fr 1fr 1fr;
    grid-gap:10px;
}

.f1-right{
    padding-left: 30px;
    display: flex;
    flex-direction: column;
}

.f1-right h2{
    margin:0 0 30px;
    font-size: 32px;
}


.f1-link3{
    color: #333;
    font-size: 15px;
    padding: 0 0 15px;
    transition: 0.5s;
}

.f1-link3:hover{
    color:#ff6900;
    transition: 0.5s;
    cursor:pointer;
}

.f1-link4{
    border-bottom: solid 1px black;
    text-underline-offset: 3px;
    text-decoration: none;
    transition:0.3s;
    color:black;
}

.f1-link4:hover{
    cursor: pointer;
    color:#fcb900;
    transition:0.3s;
    border-bottom: dotted 1px #fcb900;
}


.f1-left ul{
    list-style-type: disc;
    margin-bottom:20px;
}

.f1-left ul li{
    color: #4a4a4a;
    margin:auto;
    width: 90%;
    font-size: 15px;
    line-height: 25px;
}

.art-details{
    display: flex;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 1.3;
    margin: 0 0 20px;
    padding: 0 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 55%;

}

.art-details ul{
    list-style: none;
}


@media screen and (max-width:999px) {

    .f1{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0fr;

        
        
    }

    .f1-left{
        border-right-width: 0px;
        padding-right: 0px;
    }

    .f1-left p{
        font-size:20px;

    }

    .f1-left h1{
        margin:0;
        font-size:30px;
    }

    .f1-left h2{
        margin: 25px 0rem 25px 0px;
    }

    .imte-display{
        grid-template-columns: 1fr;
    }
    
    .f1-right{
        padding-left:0px;
        padding-top:30px;
    }

    .art-details{
        width:100%;
        flex-direction: column;
    }

    .art-details span{
        padding-right:0;
    }

}