@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


.blog-section1{
background: linear-gradient(90deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
}

.blog-section1-data{
text-align: center;
width: 80%;
margin: auto;
padding-top: 75px;
padding-bottom: 75px;
}

.blog-section1-data-left h1{
font-family: "SofiaPro-Light";
font-size: 73px;
color: white;
width: 70%;
margin: auto;
}

.blog-section2{
    height:200px;
    text-align:center;
}

.blog-section2 p{
    font-family: "SofiaPro-Light";
    font-size: 20px;
    color: black;
    width: 58%;
    margin: 2rem auto 20px auto;
    text-align: center;
}


@media screen and (max-width:599px){
    .blog-section2 p{
        font-size: 17px;
        width:80%;
    }


    .blog-section1-data-left h1 {
        font-family: "SofiaPro-Light";
        font-size: 50px;
        color: white;
        width: 100%;
        margin: auto;
    }


    .blog-section1-data {
        padding-bottom: 40px;
    }
}