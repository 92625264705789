.nf1{
    width:100%;
    height:auto;
    padding-top:7rem;
    margin:auto auto 60px auto;
    font-family:"SofiaPro-Light";
    text-align: center;
}


.nf1 h1{
    font-size: 40px;
    width: 60%;
    margin: auto auto 2rem auto;
}

.nf1 p{
    font-size: 25px;
    width: 60%;
    margin: auto auto 2rem auto;
}

.nf1 button{
    width: 200px;
    font-family: "SofiaPro-Light";
    height: 50px;
    border: none;
    background-color: #13aff0;
    color: white;
    font-size: 15px;
    transition: 0.5s;
}

.nf1 button:hover{
    transition: 0.5s;
    cursor:pointer;
    background-color: #ff6900;
}

