.b1{
    font-family: 'SofiaPro-Light';
    width: 80%;
    padding-top: 7rem;
    margin: auto auto 60px auto;
    display:grid;
    grid-template-columns: 1.3fr 0.5fr;
}


.b1-left{
    padding-right: 30px;
    border-right-width: 1px;
    border-style: solid;
    border-color: #f1f1f1;
}

.b1l-in{
    border-bottom-width:1px;
    border-style: solid;
    border-color: #f1f1f1;
    padding-bottom:30px;
}
.b1-left img{
    position:relative;
    margin-bottom:1rem;
    width:100%;
    padding-top:20px;
    
}


.b1-left p{
    color: #4a4a4a;
    margin: 0 0 20px;
    width: 90%;
    font-size: 15px;
    line-height: 25px;

}

.bmheading{
    padding: 0 0 20px;
    margin: 0 0 15px;

    letter-spacing: .6px;
}

.b1-left h1{
    font-size:30px;
    margin: 0rem 0rem 2rem 0rem;
    font-weight: 700;
}

.b1-left h2{
    margin: 0rem 0rem 2rem 0rem;
    font-size: 32px;
    font-weight: 600;
}

.b1-art-details{
    display: flex;
    color: #4a4a4a;
    font-size: 14px;
    line-height: 1.3;
    margin: 0 0 20px;
    padding: 0 0 10px;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 55%;

}

.b1-art-details ul{
    list-style: none;
}

.b1-art-details span{
    padding-right:10px;
}

.bloglist-button{
    display: inline-block;
    font-size: 11px;
    line-height: 1;
    border: 1px solid #ddd;
    padding: 10px 16px;
    border-radius: 2px;
    text-transform: capitalize;
    letter-spacing: 1px;
    background: white;
    transition:0.5s;
    
}

.bloglist-button:hover{
    border-color:#13aff0;
    color:#13aff0;
    cursor:pointer;
    transition: 0.5s;
}

.b1-right{
    padding-left: 30px;
    display: flex;
    flex-direction: column;
}

.b1-right h2{
    margin:0 0 30px;
    font-size: 32px;
}


.b1-link3{
    color:#000;
    font-size: 13px;
    padding: 0 0 15px;
    transition: 0.5s;
    width:50%;
}

.b1-link3:hover{
    color:#ff6900;
    transition: 0.5s;
    cursor:pointer;
}



@media screen and (max-width:999px) {

    .b1{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 0.1fr;
        
    }

    .b1-left{
        border-right-width: 0px;
        padding-right: 0px;
    }

    .b1-left h1{
        margin:0;
        font-size:23px;
    }

    .b1-art-details{
        width:100%;
        flex-direction: column;
    }

    .b1-art-details span{
        padding-right:0;
    }
    
    .b1-right{
        padding-left:0px;
        padding-top:30px;
    }


}