


/*------------------------------------------------ CSS QUERIES ---------------------------------------------------- */

/* -------------------------------------------------------------------------------------- SECTION 1 */
@media screen and (max-width: 599px){
  
    .s-1{
        height: 579px;
    }
    .som {
      display: block;
      height: auto;
      text-align: center;
      padding-top: 30px; /* Adjust the top padding as needed */
    }
  
    .som-left {
      margin: 20px auto; /* Adjust the margin as needed */
      width: 90%; /* Adjust the width as needed */
    }
  
    .som-left h1 {
        font-size: 32px;
        width: 100%;
        font-weight: 600;
    }
  
    .som-left p {
      width: 100%;
      font-size: 16px; /* Adjust the font-size as needed */
      padding-top: 1rem; /* Adjust the top padding as needed */
      line-height: 1.5rem; /* Adjust the line-height as needed */
    }
  
    .som-right {
      text-align: center;
    }
  
    .som-right img {
      max-width: 100%; /* Ensure the image doesn't overflow its container */
      height: auto;
      display: inline-block; /* Make sure the image is centered horizontally */
      margin: 2rem 0rem 0rem 0rem;
    }
  }


  /* CSS for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .som {
      grid-template-columns: 1fr; /* Change to a single column layout */
      height: auto; /* Adjust height to fit content */
      padding-top: 40px; /* Reduce top padding */
    }
  
    .som-left {
      margin: 30px auto; /* Center-align the content */
      width: 90%; /* Adjust width */
      text-align: center; /* Center-align text content */
    }
  
    .som-left h1 {
      font-size: 48px; /* Reduce font size for the heading */
      width: 100%; /* Adjust width */
    }
  
    .som-left p {
      font-size: 15px;
      padding-top: 2rem;
      line-height: 1.5rem;
      margin: auto;
      width:100%;
    }
  
    .s1-button {
      width: 70%; /* Adjust button width */
      margin: 2rem auto; /* Adjust margin */
      font-size: 14px; /* Reduce font size for the button */
    }
  
    .som-right img {
      width: 80%;
      height: auto;
      margin: auto;
    }
  }
  
/* CSS for screens between 800px and 1300px */
@media screen and (min-width: 800px) and (max-width: 1300px) {
  .som {
    grid-template-columns: 1fr;
    padding-top: 100px;
    text-align: center;
    grid-template-rows: 0.75fr 1fr;
    justify-items: center;
  }

  .som-left {
    width: 80%;
    text-align: center;
    margin-top: 10px;

  }

  .som-left h1 {
    font-size: 60px;
    width: 100%;
  }

  .som-left p {
    font-size: 20px;
    padding-top: 3rem;
    line-height: 2rem;
    width:100%;
  }

  .s1-button {
    margin-top: 2rem;
    font-size: 16px;
  }

  .som-right {
    width: 100%; /* Adjust the width of the right div */
    text-align: center; /* Center the contents of the right div horizontally */
  }

  .som-right img {
    width: 45%;
    height: auto;
    margin: 3rem auto;
  }
}


  
  

/* -------------------------------------------------------------------------------------------  SECTION 2 */
@media screen and (max-width: 599px) {

    
    .our_sol{
        font-size: 40px;
        font-weight: 600;
        width: 100%;
        text-align: center;
    }
    
    .our_blend{
        margin: auto;
        padding-top: 20px;
        font-size: 15px;
        text-align: center;
        line-height: 25px;
        font-weight: 300;
        color: black;
        width: 100%;
    }
    
    .card-deck{
    display:grid;
    grid-template-columns: 1fr;
    grid-template-rows:repeat(4,1fr);
    grid-gap:25px;
    margin:3rem auto auto auto;
    }
}

/* CSS for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .s-21 {
      width: 95%; /* Adjust the width */
      padding: 0 10px; /* Adjust padding */
    }

    .our_sol{
      margin: auto;
      text-align: center;
      font-size: 55px;
      font-weight: 500;
    }
  
    .our_blend {
      margin: 0 20px; /* Adjust margin */
      font-size: 18px; /* Reduce font size */
      line-height: 22px; /* Adjust line height */
      width: 90%;
    }
  
    .card-deck {
      grid-template-columns: 1fr 1fr; /* Change to 2 columns for smaller screens */
      grid-template-rows:1fr 1fr;
      grid-gap: 15px; /* Reduce grid gap */
      margin: 2rem auto; /* Adjust margin */
    }
  }
  
  /* CSS for screens between 800px and 1300px */
  @media screen and (min-width: 800px) and (max-width: 1300px) {
    .s-21 {
      width: 70%; /* Adjust the width */
      padding: 0 15px; /* Adjust padding */
    }
  
    .our_sol{
        margin:auto;
        text-align:center;
        font-size: 60px;
    }
  
    .our_blend {
      margin: 0 50px; /* Adjust margin */
      font-size: 20px; /* Increase font size */
      line-height: 24px; /* Adjust line height */
      width: 70%; /* Adjust width */
    }
  
    .card-deck {
      grid-template-columns: 1fr 1fr; /* Change to 3 columns for larger screens */
      grid-template-rows:1fr 1fr;
      grid-gap: 20px; /* Increase grid gap */
      margin: 3rem auto; /* Adjust margin */
    }
  }
  

/* -----------------------------------------------------------------------------------------SECTION 3 */
/* Media query for screens between 499px and 599px */
@media screen  and (max-width: 599px) {
    .s-31 {
      grid-template-rows: repeat(5, 0.5fr);
      grid-template-columns: 1fr; /* Switch to a single column layout */
      gap: 20px;
      text-align: center; /* Center-align text */
    }

    .modular-platform h1{
        font-size: 29px;
        font-family: "SofiaPro-Light";
        width: 100%;
        margin: auto;
        text-align: center;
        line-height: 40px;
        font-weight: 700;
    }

    .modular-platform p{
        font-size: 15px;
        font-family: "SofiaPro-Light";
        width: 100%;
        margin: 2rem auto;
        text-align: center;
    }
    
    /* Adjust padding and margins for consistency */
    .s-31 > * {
      width: 100%;
      margin: 0;
    }
  
    /* Reorganize the content with images first and adjust image width */
    .dc, .ie, .eca, .di {
      flex-direction: column-reverse;
    }

    .dc-right img,
    .ie-right img,
    .eca-right img,
    .di-right img {
        width: 90%;
    }

    /* Update styles for individual sections */
    .dc-left h1,
    .ie-left h1,
    .eca-left h1,
    .di-left h1,
    .dc-left p,
    .ie-left p,
    .eca-left p,
    .di-left p {
        font-size: 16px;
        line-height: 22px;
        text-align: left;
        margin: 10px auto;

    }

    .dc-left h1,
    .ie-left h1,
    .eca-left h1,
    .di-left h1 {
        font-size: 32px;
        line-height: 36px;
        font-weight: 600;
    }
  
    /* Adjust button styles if needed */
    .s-31 a {
      font-size: 16px;
      font-weight: 600;
    }

    /* Set the width of left sections to 100% */
    .dc-left,
    .ie-left,
    .eca-left,
    .di-left {
        width: 100%;
    }
  }

  /* Media query for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
  /* Adjust the styling for the entire section */
  .s-31 {
    grid-template-rows: repeat(5, 0.5fr);
    grid-template-columns: 1fr; /* Switch to a single column layout */
    gap: 20px;
    text-align: center; /* Center-align text */
  }

  /* Modify the styling of the modular platform */
  .modular-platform h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
  }

  .modular-platform p {
    font-size: 18px;
    margin: 2rem auto;
  }

  /* Adjust padding and margins for consistency */
  .s-31 > * {
    width: 100%;
    margin: 0;
  }

  /* Reorganize the content with images first and adjust image width */
  .dc,
  .ie,
  .eca,
  .di {
    flex-direction: column-reverse;
  }

  .dc-right img,
  .ie-right img,
  .eca-right img,
  .di-right img {
    width: 90%;
  }

  /* Update styles for individual sections */
  .dc-left h1,
  .ie-left h1,
  .eca-left h1,
  .di-left h1,
  .dc-left p,
  .ie-left p,
  .eca-left p,
  .di-left p {
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 10px auto;
  }

  .dc-left h1,
  .ie-left h1,
  .eca-left h1,
  .di-left h1 {
    font-size: 36px;
    line-height: 40px;
  }

  /* Adjust button styles if needed */
  .s-31 a {
    font-size: 20px;
    font-weight: 600;
  }

  /* Set the width of left sections to 100% */
  .dc-left,
  .ie-left,
  .eca-left,
  .di-left {
    width: 100%;
  }
}

/* Media query for screens between 800px and 1300px */
@media screen and (min-width: 800px) and (max-width: 1300px) {
  /* Adjust the styling for the entire section */
  .s-31 {
    grid-template-rows: repeat(5, 0.5fr);
    grid-template-columns: 1fr; /* Switch to a single column layout */
    gap: 20px;
    text-align: center; /* Center-align text */
  }

  /* Modify the styling of the modular platform */
  .modular-platform h1 {
    font-size: 36px;
    font-weight: 700;
    line-height: 48px;
  }

  .modular-platform p {
    font-size: 18px;
    margin: 2rem auto;
  }

  /* Adjust padding and margins for consistency */
  .s-31 > * {
    width: 100%;
    margin: 0;
  }

  /* Reorganize the content with images first and adjust image width */
  .dc,
  .ie,
  .eca,
  .di {
    flex-direction: column-reverse;
  }

  .dc-right img,
  .ie-right img,
  .eca-right img,
  .di-right img {
    width: 90%;
  }

  /* Update styles for individual sections */
  .dc-left h1,
  .ie-left h1,
  .eca-left h1,
  .di-left h1,
  .dc-left p,
  .ie-left p,
  .eca-left p,
  .di-left p {
    font-size: 20px;
    line-height: 28px;
    text-align: left;
    margin: 10px auto;
  }

  .dc-left h1,
  .ie-left h1,
  .eca-left h1,
  .di-left h1 {
    font-size: 36px;
    line-height: 40px;
  }

  /* Adjust button styles if needed */
  .s-31 a {
    font-size: 20px;
    font-weight: 600;
  }

  /* Set the width of left sections to 100% */
  .dc-left,
  .ie-left,
  .eca-left,
  .di-left {
    width: 100%;
  }
  
  /* Add some space between sections */
  .dc {
    margin-bottom: 40px;
  }

  .ie {
    margin-bottom: 40px;
  }

  .eca {
    margin-bottom: 40px;
  }

  .di {
    margin-bottom: 40px;
  }
}




/* ----------------------------------------------------------------------------------------SECTION 4 */
/* Media query for screens between 499px and 599px */
@media screen  and (max-width: 599px) {

  .s-4{
    background-image: url("../Images/wcu.png");
    width: 100%;
    height: 1525px;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    }
    
    
    .wcu h1{
        margin: auto;
        width: 80%;
        text-align: center;
        color: white;
        font-family: "SofiaPro-Light";
        font-size: 44px;
        margin-top: 4rem;
    }
    
    .wcu p{
      margin: 2.5rem auto;
      width: 95%;
      text-align: center;
      color: white;
      font-family: "SofiaPro-Light";
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
    }
    
    .s4-card-deck{
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(3,1fr);
        height: 580px;
        align-items: center;
        width: 100%;
        justify-items: center;
        margin: auto;
        grid-gap: 2rem;
    }
}

/* Media query for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
  .s-4 {
    background-image: url("../Images/wcu.png");
    width: 100%;
    height: 1400px;
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .wcu h1 {
    margin: auto;
    width: 80%;
    text-align: center;
    color: white;
    font-family: "SofiaPro-Light";
    font-size: 36px;
    margin-top: 4rem;
  }

  .wcu p {
    margin: 2rem auto;
    width: 100%;
    text-align: center;
    color: white;
    font-family: "SofiaPro-Light";
    font-size: 15px;
    line-height: 24px;
    font-weight: 500;
  }

  .s4-card-deck {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, 1fr);
    height: auto;
    align-items: center;
    width: 95%;
    justify-items: center;
    margin: auto;
    grid-gap: 2rem;
  }
}

/* Media query for screens between 800px and 1300px */
@media screen and (min-width: 800px) and (max-width: 1300px) {
  .s-4 {
    background-image: url("../Images/wcu.png");
    width: 100%;
    height: 1400px; /* Remove fixed height to prevent overflow */
    object-fit: cover;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }

  .wcu h1 {
    margin: auto;
    width: 80%;
    text-align: center;
    color: white;
    font-family: "SofiaPro-Light";
    font-size: 44px;
    margin-top: 4rem;
  }

  .wcu p {
    margin: 2.5rem auto;
    width: 80%; /* Adjust the width to ensure content fits within the screen */
    text-align: center;
    color: white;
    font-family: "SofiaPro-Light";
    font-size: 13px;
    line-height: 24px;
    font-weight: 500;
  }

  .s4-card-deck {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-gap: 2rem;
    height: auto;
    align-items: center;
    width: 80%; /* Adjust the width to ensure the grid fits within the screen */
    justify-items: center;
    margin: auto;
  }

}


/* -----------------------------------------------------------------------------------------  SECTION 5 */
@media screen  and (max-width: 599px) {
    .s5-card-deck {
        grid-template-columns:1fr; /* Change the number of columns for smaller screens */
        grid-template-rows:repeat(4,1fr);
        grid-gap: 1rem; /* Adjust the gap between cards */
        height: auto; /* Let the height be determined by content */
    }

    .eocs h1 {
        font-size: 33px;
        width: 100%;
        margin-top: 1rem;
        font-weight: 600;
    }

    .eocs p {
        font-size: 15px;
        width: 100%;
        line-height: 20px;
        margin: 2.5rem auto;
    }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
  .s5-card-deck {
      grid-template-columns: 1fr 1fr; /* 2 columns for medium-sized screens */
      grid-template-rows: auto; /* Let the rows adjust based on content */
      grid-gap: 1rem; /* Adjust the gap between cards */
      height: auto; /* Let the height be determined by content */
      justify-items: center;
  }

  .eocs h1 {
      font-size: 42px; /* Adjust font size for h1 */
      width: 100%;
      margin-top: 2rem; /* Adjust margin-top */
      font-weight: 600;
  }

  .eocs p {
      font-size: 18px; /* Adjust font size for p */
      width: 100%;
      line-height: 24px; /* Adjust line-height */
      margin: 1.5rem auto; /* Adjust margin */
  }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
  .s5-card-deck {
    grid-template-columns: 1fr 1fr; /* 2 columns for medium-sized screens */
    grid-template-rows: auto; /* Let the rows adjust based on content */
    grid-gap: 1rem; /* Adjust the gap between cards */
    height: auto; /* Let the height be determined by content */
    justify-items: center;
}

  .eocs h1 {
      font-size: 72px; /* Restore the original font size for h1 */
      width: 80%;
      margin-top: 5rem; /* Restore the original margin-top */
      font-weight: 500; /* Restore the original font weight */
  }

  .eocs p {
      font-size: 21px; /* Restore the original font size for p */
      width: 65%;
      line-height: 25px; /* Restore the original line-height */
      margin: 2.5rem auto; /* Restore the original margin */
  }
}


/* SECTION 6 */
@media screen  and (max-width: 599px) {
    .s-6{
        margin-top:3rem;
        }
        
        .wao{
        width:80%;
        margin:auto;
        }
        
        .wao h1{
            font-family: "SofiaPro-Light";
            font-size: 40px;
            text-align: center;
            width: 100%;
            margin: auto;
            font-weight: 600;
        }
        
        .wao p{
            font-family: "SofiaPro-Light";
            font-size: 17px;
            text-align: center;
            width: 100%;
        }
        
        .slider{
            margin-top:2rem;
        }
}


/* ---------------------------------------------------------------------------------------SECTION 7 */
@media screen  and (max-width: 599px) {

    .s-71 {
        text-align: center;
        align-items: center;
    }

    .s-71 h1 {
        font-size: 30px; /* Adjust font size */
        width: 80%; /* Adjust width */
        margin: 3rem auto 0 auto; /* Adjust margins */
        line-height: 2.5rem; /* Adjust line-height */
    }

    .s-71 p {
        font-size: 16px; /* Adjust font size */
        margin: 1rem auto 1rem auto; /* Adjust margins */
        line-height: 20px; /* Adjust line-height */
        width: 80%; /* Adjust width */
    }

    .inputWithButton {
        text-align: center;
        width:60%
    }

    .inputWithButton input[type="email"] {
        width: 100%;
        margin: 1rem auto;
        padding: 12px 20px;
        font-size: 14px;
    }
    

    .inputWithButton button {
        top: 1px;
        right: -39px;
        margin-top: 1rem;
        width: 40%;
        font-size: 10px;
    }

    .s-71 label {
        font-size: 14px; /* Adjust font size */
    }
}


/* CSS for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .s-71 h1 {
      font-size: 36px; /* Reduce font size for the heading */
      margin: 3rem auto 0 auto; /* Adjust top margin */
      width: 80%; /* Adjust width */
      line-height: 3.5rem; /* Adjust line height */
    }
  
    .s-71 p {
      font-size: 18px; /* Reduce font size for the paragraph */
      margin: 1rem auto 1rem auto; /* Adjust margin */
      width: 70%; /* Adjust width */
      line-height: 22px; /* Adjust line height */
    }
  
    .inputWithButton {
      flex-direction: column; /* Change to a column layout */
      align-items: center; /* Center-align the elements */
    }
  
    .inputWithButton input[type="email"] {
      width: 70%; /* Adjust the width of the input field */
      margin: 1rem auto 1rem auto; /* Adjust margin */
      padding: 10px 20px; /* Adjust padding */
    }
  
    .inputWithButton button {
        width: 20%;
        margin: 1rem auto 1rem auto;
        padding: 10px 15px;
        top: 0px;
        right: 67px;
        font-size: 10px;
    }
  }
  
  /* CSS for screens between 800px and 1300px */
  @media screen and (min-width: 800px) and (max-width: 1300px) {
    .s-71 h1 {
      font-size: 42px; /* Adjust font size for the heading */
      margin: 4rem auto 0 auto; /* Adjust top margin */
      width: 70%; /* Adjust width */
      line-height: 3.8rem; /* Adjust line height */
    }
  
    .s-71 p {
      font-size: 20px; /* Adjust font size for the paragraph */
      margin: 2rem auto 2rem auto; /* Adjust margin */
      width: 60%; /* Adjust width */
      line-height: 24px; /* Adjust line height */
    }
  
    .inputWithButton {
      flex-direction: row; /* Change back to a row layout */
      align-items: center; /* Center-align the elements */
    }
  
    .inputWithButton input[type="email"] {
      width: 35%; /* Adjust the width of the input field */
      margin: 0.5rem auto 0.5rem auto; /* Adjust margin */
      padding: 14px 27px; /* Keep padding as previously defined */
    }
  
    .inputWithButton button {
      width: 10%;
      margin: 0.5rem auto 0.5rem auto;
      padding: 2px;
      font-size: 14px;
      top: 8%;
      right: 31%;
      height: 60%;
    }
  }
  


/* ------------------------------------------------------------------------------QUERY FOR SECTION 8 */

@media screen  and (max-width: 599px) {
    .s-8{
        height:760px;
    }
    .sales {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .sales-left,
    .sales-right {
        width: 100%;
        text-align: center;
        margin: 0;
    }

    .sales-left h1 {
        font-size: 60px;
        margin: auto;
        width: 90%;
    }

    .sales-left p {
        font-size: 20px;
        margin: 20px auto;
        width: 91%;
    }

    .sales-email {
        width: 90%;
    }

    .sales-right form {
        width: 100%;
    }

    .sales-right input {
        width: 69%;
        margin: 1rem auto;
        padding: 10px 20px;
        font-size: 13px;
    }

    .sales-right button {
        width: 40%;
        margin: 1rem auto;
        font-size: 12px;
    }
}

/* CSS for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .s-8{
        height:auto;
    }
    .sales {
      grid-template-columns: 1fr; /* Change to a single column layout */
      height: auto; /* Adjust height to fit content */
      padding-top: 40px; /* Reduce top padding */
    }
  
    .sales-left {
      margin: 0 auto;
      text-align: center; /* Center-align text content */
    }
  
    .sales-left h1 {
      font-size: 48px; /* Reduce font size for the heading */
      margin: 30px auto;
      width: 90%;
    }
  
    .sales-left p {
      font-size: 18px; /* Reduce font size for the paragraph */
      margin: 30px auto;
      width: 80%;
    }
  
    .sales-email {
      width: 80%;
      margin: 0 auto; /* Center the email link */
    }
  
    .sales-right {
      margin: 20px auto; /* Adjust margin for the form */
    }
  
    .sales-right form {
      width: 90%; /* Adjust form width */
      margin: 0 auto; /* Center-align the form */
    }
  
    .sales-right input {
      width: 100%; /* Make form fields full width */
      margin: 10px 0; /* Adjust margin between form fields */
      padding: 10px 15px; /* Adjust padding */
    }
  
    .sales-right button {
      width: 60%; /* Adjust button width */
      margin: 20px auto; /* Center-align the button */
    }
  }
  
  /* CSS for screens between 800px and 1300px */
  @media screen and (min-width: 800px) and (max-width: 1300px) {
    .sales {
      padding-top: 60px; /* Adjust top padding */
    }
  
    .sales-left h1 {
      font-size: 60px; /* Increase font size for the heading */
    }
  
    .sales-left p {
      font-size: 22px; /* Increase font size for the paragraph */
    }
  
    .sales-email {
      width: 60%; /* Adjust width of the email link container */
    }
  
    .sales-right input {
      padding: 12px 18px; /* Adjust padding for form fields */
    }
  
    .sales-right button {
      width: 50%; /* Adjust button width */
      margin-top: 30px; /* Increase margin above the button */
    }
  }
  

@media screen  and (max-width: 1500px) {
    .dot-square-blue,.dot-square-purple,.s2-dot-square-purple{
        display:none;
    }
}