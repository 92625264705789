@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }

.s2-card{
height: 400px;
width: 280px;
background-color: #4988C440;
border-radius: 30px;

}

.s2-card:hover {
background: linear-gradient(90deg, #584997 0%, #498ac5 47.71%, #5ccbe0 100%);
color: white;
cursor: pointer;
}

.s2-card:hover path{
fill:white;
}


.s2-card-inner{
display:flex;
flex-direction: column;
align-items: center;
padding:47px 28px 42px 28px;
}

.s2-card-svg{
margin:auto;
}

.s2-card-heading{
margin:20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight:900;
font-size:22px;
text-align:center;
}

.s2-card-description{
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 400;
font-size: 14px;
text-align: center;
line-height: 18px;
}

.s2-card-link {
text-decoration: none; 
}

.s2-card-sentence {
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 800;
font-size: 16px;
text-align: center;
color: #000;
display: flex; /* Display as a flex container */
align-items: center; /* Vertically center the content */
}

/* Add these styles for the arrow */
.s2-card-sentence svg {
margin-left: 5px; /* Adjust the spacing between text and arrow */
}

#special:hover{
color: black;
fill: black; 
cursor: pointer;
}


/* Add this CSS rule for the card content (text and SVG) */
.s2-card-inner:hover .s2-card-svg svg path,
.s2-card-inner:hover .s2-card-sentence,
.s2-card-inner:hover .s2-card-svg svg path {
color: white;
fill: currentColor; /* Maintain the original fill color */

}

