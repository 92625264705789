@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }

.s4-card{
height: 350px;
width: 350px;
background-color: rgb(64, 128, 189);
border-radius: 30px;
transition: 0.5s all;
}

.s4-card:hover {
transform:translateY(-20px);
cursor: pointer;
}

.s4-card-inner{
display: flex;
flex-direction: column;
align-items: center;
padding: 55px;
}

.s4-card-svg{
margin:auto;
}

.s4-card-heading{
margin:20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight:900;
font-size:22px;
text-align:center;
color:#fff;
}

.s4-card-description{
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 400;
font-size: 14px;
text-align: center;
line-height: 18px;
color:#fff;
}

.s4-card-sentence {
margin: 20px auto auto auto;
font-family: "SofiaPro-Light";
font-weight: 800;
font-size: 16px;
text-align: center;
color: #fff;
display: flex; /* Display as a flex container */
align-items: center; /* Vertically center the content */
transition:0.3s;
}

/* Add these styles for the arrow */
.s4-card-sentence svg {
margin-left: 5px; /* Adjust the spacing between text and arrow */
}

.s4-card-sentence a{
color:white;
}

.s4-card-sentence:hover{
color:#6ec1e4;
cursor: pointer;
}


@media screen and (max-width:499px) {
    .s4-card{
        width:300px;
    }
}