@font-face {
    font-family: "SofiaPro-Light";
    src: url("../Fonts/b3be20d887cc494d12ade85591c774a8.eot"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff2")format("woff2"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.woff")format("woff"),
    url("../Fonts/b3be20d887cc494d12ade85591c774a8.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/b3be20d887cc494d12ade85591c774a8.svg#SofiaPro-Light")format("svg");
    }
    
    @font-face {
    font-family: "Sofia Pro Regular";
    src: url("../Fonts/060e116a70e3096c52db16f61aaab194.eot"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff2")format("woff2"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.woff")format("woff"),
    url("../Fonts/060e116a70e3096c52db16f61aaab194.ttf")format("truetype"),
    url("https://db.onlinewebfonts.com/t/060e116a70e3096c52db16f61aaab194.svg#Sofia Pro Regular")format("svg");
    }


.ss1{
background: linear-gradient(90deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
}

.ss1-data{
display: flex;
flex-direction:row;
width: 78%;
margin:auto;
padding-top: 75px;
}

.ss1-data-left h1{
font-family: "SofiaPro-Light";
font-size: 72px;
color: white;
}

.ss1-data-left p{
font-family: "SofiaPro-Light";
line-height: 2rem;
font-size: 20px;
color: white;
width: 60%;
margin-top: 25px;
}

.ss1-data-right img{
height: 100%;
width: 125%;
}


.ss2{
margin:4rem auto 2rem auto;
}

.ss2-data{
display: flex;
flex-direction: column;
width: 85%;
margin: auto;
}

.ss2-data h1{
font-family: "SofiaPro-Light";
color: black;
margin: auto;
width: 90%;
font-size: 60px;
text-align: center;
}

.ss2-data p{
font-family: "SofiaPro-Light";
color: black;
width: 90%;
margin: 4rem auto 4rem auto;
font-size: 20px;
text-align: center;
}

.ss2-data-3{
display:grid;
grid-template-columns:1.25fr 1fr;
justify-items: end;

}

.ss2-data-3-left img{
width:100%;
height:80%;
}

.ss2-data-3-right h1{
font-family: "SofiaPro-Light";
color: black;
width: 45%;
font-size: 40px;
text-align: left;
margin-top: 50px;
margin-bottom: 45px;
}

.ss2-data-3-right p{
font-family: "SofiaPro-Light";
color: black;
width: 100%;
font-size: 20px;
text-align: left;
margin-top: 20px;
margin-bottom: 20px;
line-height: 2rem;
}


.ss2-data-3-right ul{
list-style-type: disc;
width: 93%;
margin: auto;
}

.ss2-data-3-right li{
font-family: "SofiaPro-Light";
color: black;
margin: auto;
width: 100%;
font-size: 20px;
line-height: 2rem;
}


.ss3{
background: linear-gradient(90deg,#584997 0%,#498ac5 47.71%,#5ccbe0 100%);
}

#ss3-heading{
color: white;
font-family: "SofiaPro-Light";
font-size: 56px;
padding-top: 4rem;
text-align: center;
width: 75%;
margin: auto;
}

#ss3-description{
color: white;
font-family: "SofiaPro-Light";
width: 60%;
margin: 3rem auto;
text-align: center;
font-size: 20px;
line-height:1.5rem;
}
.ss3-card-deck{
display: grid;
grid-template-columns: 1fr 1fr 1fr;
width: 75%;
padding-bottom: 4rem;
margin: auto;
justify-items: center;
}


.ss4{
display:grid;
grid-template-rows:1fr 1fr 1fr;
margin:3rem 0rem;
}



.cid{
display:flex;
flex-direction:row;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
}

.cid-left{
width: 145%;
}
.cid-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 3rem;
font-weight: 500;
}

.cid-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.cid-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.cid-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}

.pasm{
display:flex;
flex-direction:row-reverse;
align-items: center;
flex-basis: 0;
margin-right: auto;
margin-left: auto;
position: relative;
width:85%;
}

.pasm-left{
width: 145%;
}
.pasm-left h1{
font-size: 40px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 3rem;
font-weight: 500;
}

.pasm-left p{
font-size: 20px;
font-family: "SofiaPro-Light";
width: 85%;
margin: 2rem auto 0rem;
line-height: 30px;

}


.pasm-left a:hover{
color:#6ec1e4;
cursor: pointer;
transition: 0.4s;
}

.pasm-right img{
width: 100%;
height: 100%;
vertical-align: middle;
display: inline-block;
height: auto;
max-width: 100%;
}

.ss5{
background: linear-gradient(90.59deg,#0f8589 -.79%,#584997 104.75%);
padding: 0px 0px 50px 0px;
}

#ss5-heading{
color: white;
font-family: "SofiaPro-Light";
font-size: 56px;
padding-top: 4rem;
text-align: center;
width: 75%;
margin: auto;
}

#ss5-description{
color: white;
font-family: "SofiaPro-Light";
width: 55%;
margin: 3rem auto;
text-align: center;
font-size: 20px;
line-height:1.5rem;
}

.ss5-card-deck{
display: grid;
grid-template-columns: 1fr 1fr 1fr 1fr;
width: 85%;
margin: auto;
justify-items: center;

}


/* SECTION S1 */

@media screen  and (max-width: 599px) {
    .ss1-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
    }

    .ss1-data-left h1 {
        font-size: 36px; /* Adjust the font size for the heading */
        margin-top: 15px; /* Add some spacing between the heading and the image */
    }

    .ss1-data-left p {
        font-size: 16px; /* Adjust the font size for the paragraph */
        width: 100%; /* You can adjust the width as needed */
        margin-top: 25px; /* Add some spacing between the paragraph and the heading */
    }

    .ss1-data-right img {
        width: 100%; /* Make sure the image takes full container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin-top: 35px; /* Add some spacing between the image and the text */
    }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
    .ss1-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        padding-top: 50px; /* Adjust top padding for better spacing */
    }

    .ss1-data-left h1 {
        font-size: 42px; /* Adjust font size for the heading */
        margin-top: 15px; /* Add some spacing between the heading and the image */
    }

    .ss1-data-left p {
        font-size: 18px; /* Adjust font size for the paragraph */
        width: 80%; /* Adjust width for better readability */
        margin: 20px auto 0px auto; /* Add some spacing between the paragraph and the heading */
        line-height: 1.8rem; /* Adjust line-height for better readability */
    }

    .ss1-data-right img {
        width: 90%; /* Make sure the image takes most of the container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin-top: 30px; /* Add some spacing between the image and the text */
    }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .ss1-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        padding-top: 50px; /* Adjust top padding for better spacing */
    }

    .ss1-data-left h1 {
        font-size: 42px; /* Adjust font size for the heading on larger screens */
        margin-top: 15px; /* Add some spacing between the heading and the image */
    }

    .ss1-data-left p {
        font-size: 18px; /* Adjust font size for the paragraph on larger screens */
        width: 80%; /* Adjust width for better readability */
        margin: 20px auto 0px auto; /* Add some spacing between the paragraph and the heading */
        line-height: 1.8rem; /* Adjust line-height for better readability */
    }

    .ss1-data-right img {
        width: 90%; /* Make sure the image takes most of the container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin-top: 30px; /* Add some spacing between the image and the text */
    }
}




/* SECTION S2 */
@media screen  and (max-width: 599px) {
    .ss2-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
    }

    .ss2-data h1 {
        font-size: 32px;
        margin: 20px 0;
        width: 100%;
    }

    .ss2-data p {
        font-size: 15px;
        width: 100%;
        margin: 10px 0;
    }

    .ss2-data-3{
        grid-template-columns: 1fr;
        grid-template-rows: 0.25fr 1fr;
    }
    .ss2-data-3-left img {
        width: 100%; /* Make sure the image takes full container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin: 20px 0; /* Add some spacing around the image */
    }

    .ss2-data-3-right h1 {
        font-size: 24px;
        text-align: left;
    }

    .ss2-data-3-right p {
        font-size: 16px;
        width: 100%;
        margin: 10px 0;
        text-align: left;
    }

    .ss2-data-3-right ul {
        list-style-type: disc;
        width: 80%; /* You can adjust the width as needed */
        margin: 20px auto; /* Center align the list and add spacing */
    }

    .ss2-data-3-right li {
        font-size: 16px; /* Adjust the font size for list items */
        line-height: 1.5rem; /* Adjust the line height for list items */
    }
}

@media screen and (min-width: 600px) and (max-width: 799px) {
    .ss2-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        width: 90%; /* Adjust width for better responsiveness */
        margin: auto; /* Center align the container */
        padding-top: 50px; /* Adjust top padding for spacing */
    }

    .ss2-data h1 {
        font-size: 36px; /* Adjust font size for the heading on medium-sized screens */
        margin: 15px 0; /* Add spacing around the heading */
        width: 100%; /* Adjust width for responsiveness */
    }

    .ss2-data p {
        font-size: 18px; /* Adjust font size for the paragraph on medium-sized screens */
        width: 100%; /* Adjust width for responsiveness */
        margin: 20px 0; /* Add spacing around the paragraph */
    }

    .ss2-data-3 {
        grid-template-columns: 1fr; /* Stack grid items vertically */
        grid-template-rows: auto; /* Auto adjust rows */
    }

    .ss2-data-3-left img {
        width: 100%; /* Make sure the image takes full container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin: 20px 0; /* Add spacing around the image */
    }

    .ss2-data-3-right h1 {
        font-size: 28px; /* Adjust font size for the subheading */
        text-align: center; /* Center align the subheading */
        margin-top: 30px; /* Add spacing above the subheading */
        margin-bottom: 20px; /* Add spacing below the subheading */
    }

    .ss2-data-3-right p {
        font-size: 18px; /* Adjust font size for the subheading text */
        width: 100%; /* Adjust width for responsiveness */
        margin: 15px 0; /* Add spacing around the subheading text */
        text-align: center; /* Center align the text */
        line-height: 1.8rem; /* Adjust line-height for better readability */
    }

    .ss2-data-3-right ul {
        list-style-type: disc; /* Use bullet points for lists */
        width: 90%; /* Adjust the width of the list */
        margin: 20px auto; /* Center align the list and add spacing */
    }

    .ss2-data-3-right li {
        font-size: 18px; /* Adjust font size for list items */
        line-height: 1.5rem; /* Adjust line height for better readability */
    }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .ss2-data {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        width: 85%; /* Adjust width for responsiveness */
        margin: auto; /* Center align the container */
    }

    .ss2-data h1 {
        font-size: 60px; /* Adjust font size for the heading on larger screens */
        margin: auto; /* Center align the heading */
        width: 90%; /* Adjust width for responsiveness */
    }

    .ss2-data p {
        font-size: 20px; /* Adjust font size for the paragraph on larger screens */
        margin: 4rem auto; /* Adjust margin for spacing */
        width: 90%; /* Adjust width for responsiveness */
    }

    .ss2-data-3 {
        grid-template-columns: 1fr; /* Stack grid items vertically */
        grid-template-rows: auto; /* Auto adjust rows */
    }

    .ss2-data-3-left img {
        width: 100%; /* Restore the original image width */
        height: 100%; /* Restore the original image height */
    }

    .ss2-data-3-right h1 {
        font-size: 40px; /* Restore the original font size for the subheading */
        text-align: center; /* Adjust text alignment for the subheading */
        margin-top: 50px; /* Restore the original margin-top for the subheading */
        margin-bottom: 45px; /* Restore the original margin-bottom for the subheading */
    }

    .ss2-data-3-right p {
        font-size: 20px; /* Restore the original font size for the subheading text */
        width: 100%; /* Adjust width for responsiveness */
        margin-top: 20px; /* Restore the original margin-top for the subheading text */
        margin-bottom: 20px; /* Restore the original margin-bottom for the subheading text */
        text-align: left; /* Adjust text alignment for the subheading text */
        line-height: 2rem; /* Adjust line-height for better readability */
    }

    .ss2-data-3-right ul {
        list-style-type: disc; /* Use bullet points for lists */
        width: 93%; /* Restore the original width for the list */
        margin: auto; /* Center align the list */
    }

    .ss2-data-3-right li {
        font-size: 20px; /* Restore the original font size for list items */
        line-height: 2rem; /* Adjust line height for better readability */
        text-align:left;
    }
}


/* SECTION S3 */
@media screen  and (max-width: 599px) {
    .ss3-card-deck {
        grid-template-columns: 1fr; /* Change to a single column grid */
        justify-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        grid-gap:2rem;
    }

    #ss3-heading {
        font-size: 36px; /* Adjust the font size for the heading */
        padding-top: 2rem; /* Reduce the padding for the heading */
        width: 90%; /* Adjust the width as needed */
    }

    #ss3-description {
        font-size: 16px; /* Adjust the font size for the description */
        width: 80%; /* Adjust the width as needed */
        margin: 2rem auto; /* Adjust the margin around the description */
        line-height: 1.5rem; /* Adjust the line height */
    }
}

/* SECTION S3 */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .ss3-card-deck {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns:1fr;
        justify-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        grid-gap: 1.5rem; /* Adjust grid gap */
    }

    #ss3-heading {
        font-size: 40px; /* Adjust the font size for the heading */
        padding-top: 2rem; /* Reduce the padding for the heading */
        width: 90%; /* Adjust the width as needed */
    }

    #ss3-description {
        font-size: 18px; /* Adjust the font size for the description */
        width: 80%; /* Adjust the width as needed */
        margin: 1.5rem auto; /* Adjust the margin around the description */
        line-height: 1.4rem; /* Adjust the line height */
    }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .ss3-card-deck {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns:1fr;
        justify-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
        grid-gap: 2rem; /* Adjust grid gap */
    }

    #ss3-heading {
        font-size: 48px; /* Adjust the font size for the heading */
        padding-top: 3rem; /* Reduce the padding for the heading */
        width: 85%; /* Adjust the width as needed */
    }

    #ss3-description {
        font-size: 20px; /* Adjust the font size for the description */
        width: 75%; /* Adjust the width as needed */
        margin: 2rem auto; /* Adjust the margin around the description */
        line-height: 1.6rem; /* Adjust the line height */
    }
}


/* SECTION S4 */
@media screen  and (max-width: 599px) {
    .cid,
    .pasm {
        flex-direction: column-reverse; /* Stack items vertically */
        align-items: center; /* Center align items horizontally */
        text-align: center; /* Center align text within the container */
    }

    .cid-left, .pasm-left{
        width:100%;
    }

    .cid-left h1,
    .pasm-left h1 {
        font-size: 24px; /* Adjust the font size for the heading */
        margin: 1rem 0; /* Add spacing around the heading */
        width: 100%; /* Adjust the width as needed */
        line-height: 1.5rem; /* Adjust line height */
        font-weight: 500; /* Optionally adjust font weight */
    }

    .cid-left p,
    .pasm-left p {
        font-size: 16px; /* Adjust the font size for paragraphs */
        width: 100%; /* Adjust the width as needed */
        margin: 1rem 0; /* Add spacing around the paragraphs */
        line-height: 1.5rem; /* Adjust line height */
    }

    .cid-right img,
    .pasm-right img {
        width: 100%; /* Make sure the image takes full container width */
        height: auto; /* Maintain aspect ratio of the image */
        margin: 1rem 0; /* Add spacing around the image */
    }

    .cid-left a:hover,
    .pasm-left a:hover {
        color: #6ec1e4;
        cursor: pointer;
        transition: 0.4s;
    }
}
/* Media query for screens between 600px and 799px */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .ss4 {
        grid-template-rows: 1fr 1fr 1fr; /* Adjust grid rows for this screen size */
        margin: 2rem 0; /* Adjust margin */
    }
    .cid,
    .pasm {
        flex-direction: column-reverse; /* Revert to row direction for larger screens */
    }

    .cid-left,
    .pasm-left {
        width: 100%; /* Adjust width for left content */
    }

    .cid-left h1,
    .pasm-left h1 {
        font-size: 24px; /* Adjust font size for heading */
        margin: 1rem 0; /* Adjust margin for heading */
        line-height: 1.5rem; /* Adjust line height for heading */
    }

    .cid-left p,
    .pasm-left p {
        font-size: 16px; /* Adjust font size for paragraphs */
        margin: 1rem 0; /* Adjust margin for paragraphs */
        line-height: 1.5rem; /* Adjust line height for paragraphs */
    }

    .cid-right img,
    .pasm-right img {
        margin: 1rem 0; /* Adjust margin for images */
    }

    .cid-left a:hover,
    .pasm-left a:hover {
        color: #6ec1e4;
        cursor: pointer;
        transition: 0.4s;
    }
}

/* Media query for screens between 800px and 1300px */
@media screen and (min-width: 800px) and (max-width: 1250px) {
    .ss4 {
        grid-template-rows: 1fr 1fr 1fr; /* Adjust grid rows for this screen size */
        margin: 3rem 0; /* Adjust margin */
    }

    .cid,
    .pasm {
        flex-direction: column-reverse; /* Revert to row direction for larger screens */
    }

    .cid-left,
    .pasm-left {
        width: 100%; /* Adjust width for left content */
    }

    .cid-left h1,
    .pasm-left h1 {
        font-size: 40px; /* Adjust font size for heading */
        margin: 2rem auto 0rem; /* Adjust margin for heading */
        line-height: 3rem; /* Adjust line height for heading */
    }

    .cid-left p,
    .pasm-left p {
        font-size: 20px; /* Adjust font size for paragraphs */
        margin: 2rem auto 0rem; /* Adjust margin for paragraphs */
        line-height: 30px; /* Adjust line height for paragraphs */
    }

    .cid-right img,
    .pasm-right img {
        margin: 2rem auto 0rem; /* Adjust margin for images */
    }

    .cid-left a:hover,
    .pasm-left a:hover {
        color: #6ec1e4;
        cursor: pointer;
        transition: 0.4s;
    }
}


/* SECTION S5 */
@media screen  and (max-width: 599px) {
    .ss5-card-deck {
        grid-template-columns: 1fr; /* Change to a single column grid */
        grid-gap:2rem;
    }

    #ss5-heading {
        font-size: 36px; /* Adjust the font size for the heading */
        padding-top: 2rem; /* Reduce the padding for the heading */
        width: 90%; /* Adjust the width as needed */
    }

    #ss5-description {
        font-size: 16px; /* Adjust the font size for the description */
        width: 80%; /* Adjust the width as needed */
        margin: 2rem auto; /* Adjust the margin around the description */
        line-height: 1.5rem; /* Adjust the line height */
    }
}

/* SECTION S5 */
@media screen and (min-width: 600px) and (max-width: 799px) {
    .ss5-card-deck {
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-gap: 1.5rem; /* Adjust grid gap */
    }

    #ss5-heading {
        font-size: 48px; /* Adjust the font size for the heading */
        padding-top: 3rem; /* Reduce the padding for the heading */
        width: 90%; /* Adjust the width as needed */
    }

    #ss5-description {
        font-size: 18px; /* Adjust the font size for the description */
        width: 80%; /* Adjust the width as needed */
        margin: 1.5rem auto; /* Adjust the margin around the description */
        line-height: 1.4rem; /* Adjust the line height */
    }
}

@media screen and (min-width: 800px) and (max-width: 1300px) {
    .ss5-card-deck {
        grid-template-columns: repeat(2, 1fr); /* Two columns */
        grid-gap: 1.5rem; /* Adjust grid gap */
    }

    #ss5-heading {
        font-size: 56px; /* Adjust the font size for the heading */
        padding-top: 4rem; /* Reduce the padding for the heading */
        width: 85%; /* Adjust the width as needed */
    }

    #ss5-description {
        font-size: 20px; /* Adjust the font size for the description */
        width: 75%; /* Adjust the width as needed */
        margin: 2rem auto; /* Adjust the margin around the description */
        line-height: 1.5rem; /* Adjust the line height */
    }
}

